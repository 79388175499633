import { template as template_20c0c98fe7784e2da274c0ebbeb0aac7 } from "@ember/template-compiler";
const FKText = template_20c0c98fe7784e2da274c0ebbeb0aac7(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
