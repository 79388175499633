import { template as template_30e248612d7a4882997e3651373cc80d } from "@ember/template-compiler";
const FKLabel = template_30e248612d7a4882997e3651373cc80d(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
